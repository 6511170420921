import { useCallback, useEffect, useState } from "react"
import services from "services"
import auth from "utils/auth"

const useDashboard = () => {
  const user = auth.getUser()

  const [tabs, setTabs] = useState([])
  const [highlights, setHighlights] = useState([])

  const [selectedId, setSelectedId] = useState()
  const [periods, setPeriods] = useState([])
  const [periodId, setPeriodId] = useState(7)

  const handleFetchTabs = useCallback((tabs) => {
    const [firstTab] = tabs
    setSelectedId(firstTab.id)
    setTabs(tabs)
  }, [])

  const fetchTabs = useCallback(
    () =>
      services.reports.tabs({ user_id: user?.user_id }).then(handleFetchTabs),
    [handleFetchTabs, user.user_id]
  )

  const handleOverview = ({ highlights }) => {
    setHighlights(highlights)
  }

  const fetchOverview = useCallback(
    () =>
      services.reports
        .overview({ business_id: selectedId, period_id: periodId })
        .then(handleOverview),
    [selectedId, periodId]
  )

  const handlePeriodsOf = (periods) => {
    setPeriods(periods)
  }

  const fetchPeriodsOf = useCallback(
    () => services.reports.periods().then(handlePeriodsOf),
    []
  )

  const onSelectTab = (id) => {
    setSelectedId(id)
  }

  const onSelectPeriod = (id) => {
    setPeriodId(id)
  }

  useEffect(() => {
    fetchTabs()
  }, [fetchTabs])

  useEffect(() => {
    fetchPeriodsOf()
  }, [fetchPeriodsOf])

  useEffect(() => {
    if (selectedId) {
      fetchOverview()
    }
  }, [selectedId, fetchOverview])

  return {
    tabs,
    periodId,
    periods,
    highlights,
    selectedId,
    onSelectTab,
    onSelectPeriod,
  }
}

export default useDashboard
