import { useState, useCallback, useEffect } from "react"
import helpers from "utils/helpers"

const usePeriodOf = ({ initialPeriodOf, periods, onSelect }) => {
  const [periodOf, setPeriodOf] = useState()
  const [active, setActive] = useState(false)

  const handleSelected = useCallback(() => {
    const getInitialPeriodOf = helpers.getItemById(periods, initialPeriodOf)
    setPeriodOf(getInitialPeriodOf?.label)
  }, [initialPeriodOf, periods])

  const onToggle = () => {
    setActive(!active)
  }

  const onSelectPeriod = (id) => {
    const getInitialPeriodOf = helpers.getItemById(periods, id)
    setPeriodOf(getInitialPeriodOf?.label)
    onSelect(id)
    setActive(false)
  }

  useEffect(() => {
    handleSelected()
  }, [handleSelected])

  return {
    periodOf,
    active,
    onToggle,
    onSelectPeriod,
  }
}

export default usePeriodOf
