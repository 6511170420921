import { Ballon, BallonItem } from "components/Ballon"
import Icon from "components/Icon"

import classnames from "classnames"
import usePeriodOf from "./hooks"

import "./PeriodOf.styl"

const PeriodOf = ({ className, initialPeriodOf, periods, onSelect }) => {
  const { periodOf, active, onToggle, onSelectPeriod } = usePeriodOf({
    initialPeriodOf,
    periods,
    onSelect,
  })

  return (
    <div className={classnames("period-of", className)}>
      <div className="period-of__wrapper" onClick={onToggle}>
        <span data-test="period-of-active">{periodOf}</span>
        <Icon
          className={classnames("period-of__icon", {
            "period-of__icon--active": active,
          })}
          name="arrow-down"
        />
      </div>
      <Ballon
        data-test="ballon-list"
        className={classnames("period-of__ballon", {
          "period-of__ballon--active": active,
        })}
      >
        {periods?.map((period) => (
          <BallonItem
            onClick={() => onSelectPeriod(period?.id)}
            key={period?.id}
          >
            {period?.label}
          </BallonItem>
        ))}
      </Ballon>
    </div>
  )
}

export default PeriodOf
