import Can from "components/Can"
import PageHeader from "components/PageHeader"
import { TabsOptions } from "components/Tabs"
import { BUSINESS_ID_NAME_LIST } from "constants/products"
import Overview from "./components/Overview"

import useDashboard from "./hooks"

const Dashboard = () => {
  const { tabs, highlights, periods, selectedId, onSelectTab, onSelectPeriod } =
    useDashboard()

  return (
    <Can
      business={[...BUSINESS_ID_NAME_LIST, ...BUSINESS_ID_NAME_LIST]}
      do={[
        ...Array(BUSINESS_ID_NAME_LIST.length).fill("leads-management"),
        ...Array(BUSINESS_ID_NAME_LIST.length).fill("admin-management"),
      ]}
    >
      <PageHeader title="Relatórios" variant="medium" />
      <section className="dashboards">
        <TabsOptions
          tabs={tabs}
          selectedId={selectedId}
          onSelect={onSelectTab}
        />
        <Overview
          periods={periods}
          highlights={highlights}
          onSelectPeriod={onSelectPeriod}
        />
      </section>
    </Can>
  )
}

export default Dashboard
