export const CARDS = {
  total_leads: {
    label: "Total",
    description:
      "Total de leads não atuados ou em atuação no período selecionado, basicamente são os leads recebidos nesse período mais os que estão sendo atuados ou ainda não foram atuados do passado, leads concluídos não entram nessa conta",
  },
  received_leads: {
    label: "Recebidos",
    description: "Leads recebidos no período selecionado",
  },
  actuated_leads: {
    label: "Atuados",
    description:
      "Do total de leads do primeiro campo, quantos foram atuados no período selecionado, independente da data de criação do lead",
  },
  un_acted_leads: {
    label: "Não atuados",
    description:
      "Do total de leads do primeiro campo, quantos ainda não foram atuados no período selecionado, independente da data de criação do lead",
  },
  average_acting: {
    label: "Atuação média/lead",
    description:
      "Média de quantas vezes houve registro de contato/tentativa para o mesmo lead",
  },
}
