import Card from "components/Card"
import Hint from "components/Hint"
import PeriodOf from "components/PeriodOf"
import Subtitle from "components/Typography/Subtitle"
import { CARDS } from "./constants"

import "./Overview.styl"

const Overview = ({ highlights, onSelectPeriod, periods }) => (
  <section className="overview">
    <Subtitle>Visão geral</Subtitle>
    <PeriodOf
      className="overview__period-of"
      periods={periods}
      onSelect={onSelectPeriod}
      initialPeriodOf={7}
    />
    <ul className="overview__list">
      {highlights?.map(([name, value]) => (
        <li className="overview__item" role={name} key={name}>
          <Card className="overview__card">
            <div className="overview__content">
              <span className="overview__label">{CARDS[name].label}</span>
              <b className="overview__value">{value}</b>
            </div>
          </Card>
          <Hint className="overview__hint">{CARDS[name].description}</Hint>
        </li>
      ))}
    </ul>
  </section>
)

export default Overview
